import * as Sentry from "@sentry/react";
import isNotDevOrTestEnv from "Shared/helpers/isNotDevOrTestEnv";

if (isNotDevOrTestEnv()) {
  Sentry.init({
    dsn: process.env.SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: process.env.NODE_ENV,
    ignoreErrors: [
      "error loading dynamically imported module",
      "TypeError: Failed to fetch dynamically imported module",
      "TypeError: Importing a module script failed.",
      "Error: Unable to preload CSS",
      "AxiosError: Request failed",
      "AxiosError: Network Error",
      "AxiosError: Request aborted",
      "AxiosError: timeout exceeded",
    ],
    // Tracing
    tracesSampleRate: 0.1,
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
