import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "rc-tooltip";

import { Flex } from "Shared/flexbox";
import type { ButtonProps } from "./Button";

type TooltippedWrapperProps = Pick<
  ButtonProps,
  | "tooltip"
  | "tooltipPlacement"
  | "tooltipMouseLeaveDelay"
  | "icon"
  | "width"
  | "disabled"
> & {
  children: React.ReactElement;
  withTooltip: boolean;
};

const TooltippedWrapper: React.FC<TooltippedWrapperProps> = ({
  children,
  tooltipMouseLeaveDelay,
  tooltipPlacement,
  tooltip,
  withTooltip,
  icon,
  width,
  disabled,
}) => {
  const { t } = useTranslation();

  const getTooltip = (icon: string) => {
    switch (icon) {
      case "caret-down":
        return t("shared.fade-in");
      case "caret-left":
        return t("shared.previous");
      case "caret-right":
        return t("shared.next");
      case "caret-up":
        return t("shared.fade-out");
      case "close":
      case "times":
        return t("shared.close");
      case "copy":
        return t("shared.copy-item");
      case "list":
        return t("", "List");
      case "pause":
        return t("time-tracking.pause");
      case "stop":
        return t("time-tracking.stop");
      case "play":
        return t("time-tracking.start");
      case "pen":
        return t("shared.edit");
      case "plus":
        return t("shared.add");
      case "save":
        return t("shared.save");
      case "sort-down":
        return t("shared.sort-ascending");
      case "sort-up-2":
        return t("shared.sort-descending");
      case "trash":
        return t("shared.delete");
      default:
        return "Click Me";
    }
  };

  return !withTooltip ? (
    children
  ) : (
    <Tooltip
      overlay={tooltip ? tooltip : getTooltip(icon || "")}
      overlayStyle={{
        zIndex: 2000,
        width: "fit-content",
      }}
      placement={tooltipPlacement}
      mouseLeaveDelay={tooltipMouseLeaveDelay}
    >
      {disabled ? (
        <Flex width={width ?? "fit-content"}>{children}</Flex>
      ) : (
        children
      )}
    </Tooltip>
  );
};

export default TooltippedWrapper;
