import React from "react";
import * as Sentry from "@sentry/react";
import isNotDevOrTestEnv from "Shared/helpers/isNotDevOrTestEnv";

const useSentryUserIdentification = (userId?: number) => {
  React.useEffect(() => {
    if (isNotDevOrTestEnv()) {
      Sentry.setUser(userId ? { id: userId } : null);
      // if (!userId)
      //   Sentry.getCurrentScope().clear();
    }
  }, [userId]);
};

export default useSentryUserIdentification;
