import Cookies from "universal-cookie";

const initXSRFToken = () => {
  const rootUrl = process.env.REACT_APP_API_ROOT_URL?.includes(":")
    ? (process.env.REACT_APP_API_ROOT_URL.split(":")[0] ?? "")
    : process.env.REACT_APP_API_ROOT_URL;
  const cookies = new Cookies();
  cookies.set("XSRF-TOKEN", "xxx", {
    domain: rootUrl?.startsWith("https://")
      ? rootUrl.replace("https://", "")
      : rootUrl?.startsWith("http://")
        ? rootUrl.replace("http://", "")
        : "localhost",
    sameSite: "strict",
  });
};

export default initXSRFToken;
